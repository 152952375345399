import DataTable from "datatables.net-dt";
import "jquery.fancytree/dist/modules/jquery.fancytree.filter";

var dataTableJsonFile;
var partner_id = $('#partner_id').val();
let table_id = "#user-table"
var data_file_url = '/api/get-data-users/';
if(partner_id===undefined){
    data_file_url = "/api/get-data-users/";
}
else{
    data_file_url = "/api/get-data-users/?key=GEr5v6yjGhj&partner_id="+partner_id;
}
load_data_json();


let table = $(table_id).DataTable({
	info: false,
	pagingType: "numbers",
	pageLength: 20,
	order: [[0, 'asc']],
	columnDefs: [{ width: '70%', targets: 0 }],
	language: {
		url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/ru.json',
	},
	columns: [
		// {
		// 	data: 'ava',
		// 	"createdCell": function (td, cellData, rowData, row, col) {
		// 		if (cellData) {
		// 			$(td).html("<img src='" + cellData + "' loading='lazy' class='user-table-ava'>");
		// 		} else {
		// 			$(td).html("<div class='user-table-ava'></div>");
		// 		}
		// 	}
		// },
		{data: 'name'},
		{data: 'phone', className: 'td-nowrap'},
		{data: 'email', className: 'td-nowrap'},
		{data: 'type', className: 'td-nowrap'},
		{data: 'status', "searchable": false},
		{data: 'actions', "searchable": false},
	],
});


$(function () {

	$('.js-accordion-filter').on('change', function () {
		order_filter_reload()
	});

	$(".js-table-search").on('input', function () {
		let val = this.value;

		table.search(val).draw();
	});


	$('.js-accordion-check-all').on('change', function () {
		let checked = $(this).prop('checked');

		$('.js-accordion-filter').prop('checked', checked);
		order_filter_reload();
	});

})

function order_filter_reload() {
	let filter = [];

	$('.js-accordion-filter:checked').each(function () {
		filter.push($(this).val());
	})

	order_table_filter(filter);
}

function load_data_json() {
	$.ajax({
		url: data_file_url,
		success: function (data) {
			dataTableJsonFile = data.data;
			table_init(dataTableJsonFile);
		}
	},)
}

function table_init(data) {
	table.clear().rows.add(data).draw();
}

function order_table_filter(filter) {
	let filterData = dataTableJsonFile.filter(function (value, index) {
		return filter.includes(value.status);
	});
	table_init(filterData);
}

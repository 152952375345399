//import {cart_calc} from "../functions/cart_calc";

$(function () {
    // Payment.init(); - Убрали выблр контрагента только при безналичном расчете 
    Delivery.init();
    
});
var Payment = {
    init: function(){
        $('.payment-content').hide();
        if ($(".js-select-payment_method").length > 0) {
             $(".js-select-payment_method").on('change',function(){
                    var payment_method = $('.js-select-payment_method option:selected').data("payment_method");
                    console.log('payment_method: ' + payment_method);
                    $('.payment-content').hide();
                    if ($("#js-payment-"+payment_method+"-content").length > 0) {
                        //console.log('show: .js-delivery-'+ delivery_method+'-content');
                        $("#js-payment-"+payment_method+"-content").show();
                    }
                });
        }
    }
    
}
var Delivery = {
        init: function(){
            $('.delivery-content').hide();
            if ($(".js-delivery-switch").length > 0) {
                $(".js-delivery-switch").on('change',function(){
                    console.log('js-delivery-switch change');
                    $(".switch-item").removeClass("active");
                    if ($(this).prop('checked') === true) {
                        console.log('js-delivery-alternative change');
                        $(".js-delivery-alternative").addClass("active");
                    }
                    else{
                        console.log('js-delivery-default change');
                        $(".js-delivery-default").addClass("active");
                    }
                });
           }
            $('.delivery-to-content').hide();
            if ($(".js-select-delivery_method").length > 0) {
                $('.js-select-delivery_method').select2(
                    {
                        language: {
                            "noResults": function(){
                                return "Ничего не найдено";
                            }
                        }
                    });
//                    $('.js-select-delivery_method').on('select2:select', function(e) {
//                        console.log('отловили событие 5');
//                    });

                $(".js-select-delivery_method").on('select2:select',function(){
                    let delivery_method = $('.js-select-delivery_method option:selected').data("delivery_method");
                    console.log('delivery_method: ' + delivery_method);
                    $('.delivery-content').hide();
                    if ($("#js-delivery-"+delivery_method+"-content").length > 0) {
                        //console.log('show: .js-delivery-'+ delivery_method+'-content');
                        $("#js-delivery-"+delivery_method+"-content").show();
                    }
                });
                
            }
            if ($(".js-select-delivery_to").length > 0) {
                $(".js-select-delivery_to").on('change',function(){
                    let delivery_to = $('.delivery_to option:selected').data("delivery_to");
                    console.log('delivery_to: ' + delivery_to);
                    $('.delivery-to-content').hide();
                    if ($("#js-delivery-to-"+delivery_to+"-content").length > 0) {
                        //console.log('show: .js-delivery-'+ delivery_method+'-content');
                        $("#js-delivery-to-"+delivery_to+"-content").show();
                    }
                });
            }
            if ($(".js-delivery_address_city").length > 0) {
                $(".js-delivery_address_city").on('change',function(){
                    address = $(this).val();
                    delivery_discount =  Number($('#delivery_discount').val()),
                    weight = Number($('#order_weight').val());
                    var data_file_url = '/api/get-data-delivery/?key=GEr5v6yjGhj&address='+address+'&discount='+delivery_discount+'&weight='+weight;
                    console.log('data_file_url: '+data_file_url);
                    var delivery_cost = Delivery.getAjaxDeliveryCost(data_file_url);
                    console.log('delivery_cost: '+delivery_cost);
                });
            }
            if ($('input[name="as_partners_shops_id"]').length > 0){
                console.log('as_partners_shops_id: init 5');
                $('input[name="as_partners_shops_id"]').on('click',function(){
                    let shop_id = $(this).val();
                    Delivery.getShopArray(shop_id);
                });
            }
        },
        init_old: function(){
            $('.delivery-content').hide();
            //Delivery.hide($(".delivery-content"));
            if ($(".js-select-delivery_method").length > 0) {
                $(".js-select-delivery_method").on('click',function(){
                    var delivery_method = $('.js-select-delivery_method option:selected').data("delivery_method");
                    //console.log('delivery_method: ' + delivery_method);
                    $('.delivery-content').hide();
                    if ($("#js-delivery-"+delivery_method+"-content").length > 0) {
                        //console.log('show: .js-delivery-'+ delivery_method+'-content');
                        $("#js-delivery-"+delivery_method+"-content").show();
                    }
                });
                
            }
            if ($(".js-select-delivery_price").length > 0) {
                $(".js-select-delivery_price").on('change',function(){
                    var selected_item = $('.js-select-delivery_price option:selected');
                    var delivery_cost = 0;
                    if(Number(selected_item.val())>0){
                        var price = Number(selected_item.data("delivery_price")),
                        price_preparation = Number(selected_item.data("delivery_price_preparation")),
                        delivery_discount =  Number($('#delivery_discount').val()),
                        weight = Number($('#order_weight').val());
                        console.log('price: '+ price);
                        console.log('price_preparation: '+ price_preparation);
                        var delivery_cost = (weight*price + price_preparation)-(weight*price + price_preparation)*delivery_discount/100;
                        
                    }
                    $('.delivery-price').html(delivery_cost+' &#8381;');
                    $('.delivery-cost').val(delivery_cost);
                    //cart_calc();
                });
            }
        },
        calculate:function(){
            
        },
        hide:function(item){
            item.each(function () {
                $(this).hide();
           });
        },
        updateCost: function(){
            var delivery_cost = $('#delivery_cost').val(),
                rise_cost = $('#rise_cost').val(),
                order_cost = $('order_cost').val();
            var final_cost = (Number(delivery_cost)+Number(rise_cost)+Number(order_cost)).toLocaleString()+' &#8381;';
            console.log('Финальная стоимость: ' + final_cost);
            $('final_cost').val(final_cost);
        },
        getShopArray: function(shop_id){
            let address = '',
                transport_company = '',
                transportation = {
                    '13c4fdeb-fecb-11e8-9b89-00155d15240d': 'Доставка до магазина',
                    1: "b",
                    length: 2,
};
                
            if(shop_id*1>0){
                let data_file_url = '/api/get-data-shop/?key=GEr5v6yjGhj&shop-id='+shop_id;
                $.ajax({
                    url: data_file_url,
                    success: function (data) {
                        var shop = data.data;
                        console.log(shop);
                        if(shop.as_delivery_to_id > 0 && shop.delivery_to!='' && shop.delivery_destination!='' && shop.delivery_destination_label!=''){
                            delivery_block = '<h2>'+shop.delivery_to+'</h2>'+
                                             '<p>'+shop.delivery_destination_label+': '+shop.delivery_destination+'</p>';
                        }
                        else{
                            delivery_block = '<h2>У магазина не указан способ доставки</h2>'+
                                             '<p>Обратитесь к Вашему менеджеру, изменить способ доставки</p>';
                            let errors = $('#order_errors').val();
                            errors+='| У магазина не указан способ доставки по умолчанию, либо указан не допустимый способ доставки';
                            $('#order_errors').val(errors);
                        }
                        $('#delivery_block_replace').html(delivery_block);
                    }
                },)
            }
            
        },
        getAjaxDeliveryCost: function(data_file_url){
            $.ajax({
                url: data_file_url,
                success: function (data) {
                    var delivery_cost = data.data;
                    console.log('delivery_cost: ' + delivery_cost);
                    return delivery_cost;
                }
            },)
        }
    };
import {hits} from 'instantsearch.js/es/widgets';

let view = localStorage.getItem('facet_view');

if (!view) view = 'table';

$('.facet-block').addClass("view-" + view);

for (let i = 0; i < 5; i++) {
	$('[data-widget="hits"]').append("<div class='product-itm-skeleton'></div>");
}

const product_item_grid = (itm) => `
	<div class="catalog-itm cart-itm">

		<input name="price-pack" type="hidden" value="'.$product_cost.'">
		<input name="price-pack-discount" type="hidden" value="'.$product_cost.'">

		<input name="price-m" type="hidden" value="'.$product_cost_m2.'">
		<input name="weight" type="hidden" value="'.$row['weight'].'"><!--Вес 1 упаковки-->

		<!--<div class="catalog-cart-block">
				<input class="js-checkbox js-cart-itm-select" data-placeholder="" type="checkbox">
				<div class="catalog-cart-block__remove js-remove-cart-itm">
						<a href="javascript:void(null);" onclick="xajax_Delete_Cart_Product(\\'sid='.$sid.'&cart_id='.$row['id'].'&block=cart_list\\'); return false;"><img alt="" class="svg" src="/skins/img/remove.svg"></a>
				</div>
		</div>-->

		<div class="catalog-itm__top">
			<a class="catalog-itm__top__link" href="/catalog/product-info/?product_id=${itm.id}">
				<img alt="" class="catalog-itm-label-right" src="${itm.brand_img}">
				<!--<div class="catalog-itm-label">
						<span class="catalog-itm-label__name-ico">🏆</span>
						<span class="catalog-itm-label__name"> хит сезона</span>
				</div>-->
				<div class="catalog-itm-slider">
					<div class="catalog-itm-slider__itm">
						<img alt="${itm.name}" class="" loading="lazy" src="${itm.image}">
					</div>
				</div>

				<!--<div class="catalog-itm-rating">
					<img alt="" class="svg star" src="/skins/img/star.svg">
					<img alt="" class="svg star" src="/skins/img/star.svg">
					<img alt="" class="svg star" src="/skins/img/star.svg">
					<div class="catalog-itm-rating__num">13</div>
				</div>-->

			</a>
			<!--<a class="catalog-itm__top__3d" href="/3d">
				<img alt="" class="svg" src="/skins/img/tmp/3d-phone.svg">
				<span class="catalog-itm__top__3d__placeholder">доступен для примерки по вашим фото</span>
			</a>

			<div class="catalog-itm__top__actions">
				<div class="catalog-itm__top__actions__btn js-add-fav">
					<img alt="" class="svg" src="/skins/img/heart.svg">
				</div>
				<div class="catalog-itm__top__actions__btn js-add-compare">
					<img alt="" class="svg" src="/skins/img/compare.svg">
				</div>
			</div>-->

		</div>
		<div class="catalog-itm__bot">
			<div class="catalog-itm__bot__top">
				<a class="catalog-itm__name" href="/catalog/product-info/?product_id=${itm.id}">
					${itm._highlightResult.name.value}
				</a>
				<div class="catalog-itm__attr">класс износостойкости: ${itm.wear_class}</div>
				<div class="catalog-itm__attr">толщина: ${itm.depth === "0" ? itm.depth_tile : itm.depth} мм</div>
				<div class="catalog-itm__attr">способ укладки: ${itm.laying_method}</div>
			</div>

			<div class="catalog-itm__bot__bot">

				<div class="catalog-itm-prices">
					<div class="catalog-itm-prices__top">
						<div class="catalog-itm-prices__text">Цена за:</div>
						<div class="catalog-itm-prices__navs">
							${itm.calc_price_m2 !== "0" ? `<div class="catalog-itm-prices__nav">м<span class="square">2</span></div>` : ""}
							<div class="catalog-itm-prices__nav active">упаковку</div>
						</div>
					</div>

					<div class="catalog-itm-prices__contents">
						<div class="catalog-itm-prices__content">
							<span class="price js-base-price">${itm.calc_price_m2} ₽</span>
							<span class="price price--dealer js-calc-price">${itm.calc_dealer_price_m2} ₽</span>
						</div>
						<div class="catalog-itm-prices__content active">
							<span class="price js-base-price">${itm.calc_price} ₽</span>
							<span class="price price--dealer js-calc-price">${itm.calc_dealer_price} ₽</span>
						</div>
					</div>
				</div>
				<button class="btn-line small w100 add-cart-btn btn__add-to-cart" 
						data-graph-path="product-1" 
						data-m2_in_package="${itm.m2_in_package}" 
						data-name="${itm.name}" 
						data-preview="${itm.image}" 
						data-price="${itm.calc_price_no_format}" 
						data-price-dealer="${itm.dealer_price}" 
						data-product_id="${itm.id}">
						<svg class="svg">
                            <use xlink:href="/skins/img/sprite.svg#shopping-cart"></use>
                        </svg>

					в корзину
				</button>
				</div>
			</div>
		</div>
	</div>
`;

const product_item_table = (itm) => `
		<div class="hit-table-grid__td">
			<a href="/catalog/brand-info/?brand_id=${itm.as_product_brand_id}">${itm.brand}</a>
		</div>
		<div class="hit-table-grid__td"><a href="/catalog/collection-info/?collection_id=${itm.as_product_collections_id}">${itm.collection}</a></div>
		<div class="hit-table-grid__td">${itm.vendor_code}</div>
		<div class="hit-table-grid__td">
			<a href="/catalog/product-info/?product_id=${itm.id}" class="t-cat__link">
				${itm.name}
				
				<span class="popup-preview" role="tooltip">
				<span class="visually-hidden">Контекстное меню: </span>
					<article class="popup-preview__card">
					<picture><img alt="Предпоказ товара" class="popup-preview__image" height="120" loading="lazy" src="${itm.image}" width="120"></picture>
					</article>
				</span>
			</a>
		</div>
		<div class="hit-table-grid__td">
			<span class="table-price-col">
			<span class="js-base-price">${itm.calc_price} ₽</span>
			<span class="js-calc-price">${itm.calc_dealer_price} ₽</span>
			</span>
		</div>
		<div class="hit-table-grid__td">${itm.bonus}</div>
		<div class="hit-table-grid__td">${itm.amount}</div>
		<div class="hit-table-grid__td hit-table-grid__td--action">
		<div class="t-cat-menu__item">
			<button class="btn-reset btn__action btn__menu-popup js-show-table-popup" aria-label="Открыть контекстное меню"><svg class="svg-points"><use xlink:href="/skins/img/sprite.svg#points"></use></svg></button></div>
			<div class="t-cat-menu__item">${itm.show_on_stand_btn}</div>
			<!--<div class="t-cat-menu__item"><button onclick="xajax_Add_To_Favorites(${itm.id});  return false;" class="btn-reset btn__action js-add-fav"><svg class="svg-standart"><use xlink:href="/skins/img/sprite.svg#bookmark"></use></svg><svg class="svg-fill"><use xlink:href="/skins/img/sprite.svg#bookmark-fill"></use></svg></button></div>-->
			<div class="t-cat-menu__item">
				<button class="btn-reset btn__action btn__add-to-cart" 
                                    data-graph-path="product-1" 
                                    data-m2_in_package="${itm.m2_in_package}" 
                                    data-name="${itm.name}" 
                                    data-preview="${itm.image}" 
                                    data-price="${itm.calc_price_no_format}" 
                                    data-price-dealer="${itm.dealer_price}" 
                                    data-product_id="${itm.id}">
                                    <svg><use xlink:href="/skins/img/sprite.svg#shopping-cart-add"></use></svg>
				</button>
			</div>
			
			<span class="popup-menu" role="tooltip">
				<span class="visually-hidden">Контекстное меню: </span>
				<div class="popup-menu__item">
					<a href="javascript:void(0);" onclick="xajax_Add_To_Favorites(${itm.id});  return false;" class="popup-menu__link js-add-fav">
						<span class="popup-menu__icon">
						<svg class="svg-standart"><use xlink:href="/skins/img/sprite.svg#bookmark"></use></svg>
						<svg class="svg-fill"><use xlink:href="/skins/img/sprite.svg#bookmark-fill"></use></svg>
						</span>
						<span class="popup-menu__title">Добавить в избранное</span>
					</a>
				</div>
				<div class="popup-menu__item">
					<button data-text="${itm.ref_link}" class="popup-menu__link js-copy-buffer">
						<span class="popup-menu__icon">
						<svg><use xlink:href="/skins/img/sprite.svg#copy-link"></use></svg>
						</span>
						<span class="popup-menu__title">Скопировать ссылку</span>
					</button>
				</div>
				<div class="popup-menu__item">
					<a href="${itm.pdf_link}" class="popup-menu__link">
						<span class="popup-menu__icon">
						<svg><use xlink:href="/skins/img/sprite.svg#pdf"></use></svg>
						</span>
						<span class="popup-menu__title">Скачать PDF</span>
					</a>
				</div>
			</span>
			
		</div>
`;

export const products = hits({
	container: '[data-widget="hits"]',
	transformItems(items) {
		$('.facet-block').addClass("load");

		let widget = $('[data-widget="hits"]');

		if (view === 'table' && $('.hit-table-grid-head').length === 0) {
			const th = ['Бренд', 'Коллекция', 'Артикул', 'Наименование', 'Цена', 'Баллы', 'Упаковок', 'Действие']

			widget.wrapAll('<div class="hit-table-grid"></div>')
			widget.parent().prepend('<div class="hit-table-grid-head"></div>')
			let head = widget.siblings(".hit-table-grid-head");
			th.forEach((th) => {
				head.append(`<div class="hit-table-grid__th">${th}</div>`)
			})
		}

		return items;
	},
	templates: {
		item(itm) {
			let price_m2 = priceFormat(itm.price_m2),
				price_m2_discount = false,
				price = priceFormat(itm.price),
				price_no_format = itm.price,
				price_discount = false;

			if (price_m2 !== priceFormat(itm.price_m2_discount)) {
				price_m2_discount = priceFormat(itm.price_m2);
				price_m2 = priceFormat(itm.price_m2_discount);
			}

			if (price !== priceFormat(itm.price_discount)) {
				price_discount = priceFormat(itm.price);
				price = priceFormat(itm.price_discount);
				price_no_format = itm.price_discount;
			}

			let dealer_price_m2 = itm.dealer_price / itm.m2_in_package,
				calc_dealer_price_m2 = priceFormat(dealer_price_m2)

			itm = {
				...itm,
				calc_price_m2: price_m2,
				calc_price: price,
				calc_price_no_format: price_no_format,
				calc_price_m2_discount: price_m2_discount,
				dealer_price_m2: dealer_price_m2,
				calc_dealer_price_m2: calc_dealer_price_m2,
				calc_price_discount: price_discount,
				calc_dealer_price: priceFormat(itm.dealer_price)
			}

			return view === 'grid' ? product_item_grid(itm) : product_item_table(itm)
		}
		,
		empty(searchResults) {
			const hasRefinements = searchResults.getRefinements().length > 0;
			const description = hasRefinements
				? 'Попробуйте сбросить примененные вами фильтры.'
				: 'Пожалуйста, попробуйте другой запрос.';

			return `
			  <div class="hits-empty-state">
				<svg
				  class="hits-empty-state-image"
				  height="138"
				  width="138"
				  xmlns="http://www.w3.org/2000/svg"
				  xmlns:xlink="http://www.w3.org/1999/xlink"
				>
				  <defs>
					<linearGradient id="c" x1="50%" x2="50%" y1="100%" y2="0%">
					  <stop offset="0%" stop-color="#F5F5FA" />
					  <stop offset="100%" stop-color="#FFF" />
					</linearGradient>
					<path
					  d="M68.71 114.25a45.54 45.54 0 1 1 0-91.08 45.54 45.54 0 0 1 0 91.08z"
					  id="b"
					/>
					<filter
					  filterUnits="objectBoundingBox"
					  height="140.6%"
					  id="a"
					  width="140.6%"
					  x="-20.3%"
					  y="-15.9%"
					>
					  <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
					  <feGaussianBlur
						in="shadowOffsetOuter1"
						result="shadowBlurOuter1"
						stdDeviation="5.5"
					  />
					  <feColorMatrix
						in="shadowBlurOuter1"
						result="shadowMatrixOuter1"
						values="0 0 0 0 0.145098039 0 0 0 0 0.17254902 0 0 0 0 0.380392157 0 0 0 0.15 0"
					  />
					  <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2" />
					  <feGaussianBlur
						in="shadowOffsetOuter2"
						result="shadowBlurOuter2"
						stdDeviation="1.5"
					  />
					  <feColorMatrix
						in="shadowBlurOuter2"
						result="shadowMatrixOuter2"
						values="0 0 0 0 0.364705882 0 0 0 0 0.392156863 0 0 0 0 0.580392157 0 0 0 0.2 0"
					  />
					  <feMerge>
						<feMergeNode in="shadowMatrixOuter1" />
						<feMergeNode in="shadowMatrixOuter2" />
					  </feMerge>
					</filter>
				  </defs>
				  <g fill="none" fill-rule="evenodd">
					<circle
					  cx="68.85"
					  cy="68.85"
					  fill="#5468FF"
					  opacity=".07"
					  r="68.85"
					/>
					<circle
					  cx="68.85"
					  cy="68.85"
					  fill="#5468FF"
					  opacity=".08"
					  r="52.95"
					/>
					<use fill="#000" filter="url(#a)" xlink:href="#b" />
					<use fill="url(#c)" xlink:href="#b" />
					<path
					  d="M76.01 75.44c5-5 5.03-13.06.07-18.01a12.73 12.73 0 0 0-18 .07c-5 4.99-5.03 13.05-.07 18a12.73 12.73 0 0 0 18-.06zm2.5 2.5a16.28 16.28 0 0 1-23.02.09A16.29 16.29 0 0 1 55.57 55a16.28 16.28 0 0 1 23.03-.1 16.28 16.28 0 0 1-.08 23.04zm1.08-1.08l-2.15 2.16 8.6 8.6 2.16-2.15-8.6-8.6z"
					  fill="#5369FF"
					/>
				  </g>
				</svg>
			
				<p class="hits-empty-state-title">
					Извините, мы не можем найти совпадений по вашему запросу!
				</p>
				<p class="hits-empty-state-description">
				  ${description}
				</p>
			  </div>
			`;
		}
	}, cssClasses: {
		root: 'MyCustomHits',
		list: ['MyCustomHitsList', 'MyCustomHitsList--subclass'],
	},
});

const priceFormat = (price) => {
	return parseInt(price).toLocaleString(
		undefined, // leave undefined to use the visitor's browser
		// locale or a string like 'en-US' to override it.
		{minimumFractionDigits: 0}
	);
}


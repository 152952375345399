import {validate_form} from "../functions/validate_form";

$(function () {

	init_checkbox_style();
	init_radio_style();
        init_select_style();

	$('input[type="tel"]').mask('+9 (999) 999-99-99',
		{
			completed: function () {
				let form = $(this).closest('.form-validate');
				if (form.length > 0) {
					validate_form(form);
				}
			}
		})

	$('.mask-code').mask('9 9 9 9',
		{
			completed: function () {
				let form = $(this).closest('.form-validate');
				if (form.length > 0) {
					validate_form(form);
				}
			}
		})

	$('.input-password-ico').on('click', function () {
		$(this).toggleClass('show');

		if ($(this).hasClass('show')) {
			$(this).siblings('input').attr('type', 'text');
		} else {
			$(this).siblings('input').attr('type', 'password');
		}
	});

	$('.form-validate .required').on('input', function () {
		let form = $(this).closest('.form-validate');

		validate_form(form)
	});

	$('.form-validate').on('submit', function () {
		if (!validate_form($(this))) {
			return false;
		}
	});

	setTimeout(function () {
		$('.form-validate').each(function () {
			validate_form($(this), false);
		});
	}, 1000);

	$('.js-open-modal').on('click', function () {
		let id = $(this).attr('href');

		open_modal($(id));
	});
        $('.js-modal-xajax-result').on('click', function () {
            let dialog_msg = '<h2>Обрабатываем запрос</h2><p>Нужно немного подождать...</p>';
            $('#modal-success-content-replace').html(dialog_msg);
            open_modal($('#modal-success'));
        });
        $('.js-modal-handler').on('click', function () {
            let modal_h = 'Обрабатываем запрос';
            let modal_msg = 'Нужно немного подождать...';
            if($(this).data('modal-h').length>0){
                modal_h = $(this).data('modal-h');
            }
            if($(this).data('modal-msg').length>0){
                modal_msg = $(this).data('modal-msg');
            }
            
            let modal_block = '<h2>'+modal_h+'</h2><p>'+modal_msg+'</p>';
            $('#modal-success-content-replace').html(modal_block);
            open_modal($('#modal-success'));
        });

	$('.js-close-modal').on('click', close_modal);

	$('.js-input-copy').on('click', function () {
		var text = $(this).siblings('input').val();

		navigator.clipboard.writeText(text).then(function () {
			open_modal($('#modal-copy-buffer'));
		}, function (err) {
			alert('Ошибка при копировании текста в буфер обмена');
		});
	});

	$('.accordion-head').on('click', function (e) {
		if ($(e.target).closest("label").length > 0) {
			return true;
		}

		$(this).closest('.accordion').toggleClass('active');
		$(this).toggleClass('active');
		$(this).siblings('.accordion-content').slideToggle();
		$(this).siblings('.accordion-body').slideToggle();
	});

	$('.page__body').on('click', function (e) {
		if ($(this).hasClass('menu-open') && !$(e.target).closest('.header,.sidebar-wrapper').length) {
			$('.header-gamburger').click();
		}
	});

	$('.js-counter-up,.js-counter-down').on('click', function () {
		let input = $(this).siblings('input'),
			num = parseInt(input.val());

		if ($(this).hasClass('js-counter-up')) {
			++num;
		} else {
			--num;
		}

		if (!num) num = 1;
		if (num < 1) num = 1;
		if (num > 999) num = 999;

		input.val(num);
	});

	$('.counter input').on('input', function () {
		let num = parseInt($(this).val());

		if (!num) num = 1;
		if (num < 1) num = 1;
		if (num > 999) num = 999;

		$(this).val(num);
	})

	$('.user-ava input').on('input', function (e) {
		let file = $(this)[0].files;

		if (file.length === 0) return;

		let type = file[0].type.toLowerCase(),
			path = URL.createObjectURL(e.target.files[0]),
			block = $(this).closest('.user-ava');

		block.find('.input-hint').remove();

		if (!(type.indexOf("png") !== -1 || type.indexOf("jpg") !== -1 || type.indexOf("jpeg") !== -1 || type.indexOf("gif") !== -1)) {
			$(this).val("");
			block.append('<span class="input-hint">Неправильное расширение файла</span>');
			return false;
		}

		if (file[0].size > 5 * 1024 * 1024) {
			$(this).val("");
			block.append('<span class="input-hint">Слишком большой размер файла</span>');
			return false;
		}

		block.addClass("loading").removeClass("error");

		var bgimage = new Image();
		bgimage.src = path;

		$(bgimage).on('load error', function () {
			$('.user-ava__img').css('background-image', 'url(' + path + ')');

			block.removeClass("empty").removeClass("loading");
		});

		if ($(this).closest('#formGallery').length > 0) {
			block.removeClass('error');
		}
	});

	$('.js-ava-remove').on('click', function () {
		let block = $(this).closest('.user-ava');

		block.addClass("empty");
		block.find('input').val('');
		block.find('.user-ava__img').css('background-image', 'none');
	})

	$('.password-show').on('click', function () {
		$(this).toggleClass('show');

		if ($(this).hasClass('show')) {
			$(this).siblings('input').attr('type', 'text');
		} else {
			$(this).siblings('input').attr('type', 'password');
		}
	});

	$('.js-close-cart-popup').on('click', function (e) {
		$('.cart-popup').removeClass('active');
	});

	$('.cart-popup-hover').on('click', function (e) {
		if ($(e.target).hasClass('.cart-popup') || $(e.target).closest('.cart-popup').length > 0) {
			return;
		}
		$(this).find('.cart-popup').toggleClass('active');
	});

	$(document).on('click', '.js-add-fav,.js-add-compare', function () {
		$(this).toggleClass("active");
	});

	$(document).on('click', '.js-add-cart-view', function () {
		$(this).closest('.add-cart-block').addClass("active");
		$(this).closest('.add-cart-block').find('.add-cart-counter__val').val(1);

		return false;
	});

	$(document).on('click', '.js-change-counter', function () {
		let input = $(this).siblings('.add-cart-counter__val'),
			val = parseInt(input.val());

		if ($(this).hasClass('left')) {
			--val;
		} else {
			++val;
		}

		if (val <= 0) {
			$(this).closest('.add-cart-block').removeClass('active');
			val = 0;
		}

		if (val > 9999) {
			val = 9999;
		}

		input.val(val);
	});

	$(document).on('input', '.add-cart-counter__val', function () {
		var val = parseInt(this.value.replace(/[^0-9\.]/g, ''));

		if (!val) {
			val = 1;
		}

		if (val <= 0) {
			$(this).closest('.add-cart-block').removeClass('active');
		}

		if (val > 9999) {
			val = 9999;
		}

		this.value = val;
	});

	$(document).on('click', '.catalog-itm-prices__nav', function () {
		let parent = $(this).closest('.catalog-itm-prices'),
			nav = parent.find('.catalog-itm-prices__nav'),
			content = parent.find('.catalog-itm-prices__content'),
			i = $(this).index();

		nav.removeClass('active');
		$(this).addClass('active');
		content.removeClass('active');
		content.eq(i).addClass('active');
	});


	$(document).click(function (event) {
		if ($(event.target).closest(".js-show-table-popup,.popup-menu").length) {
			return;
		}

		$('.popup-menu').removeClass("show");
	});

	$(document).on('click', '.js-show-table-popup', (e) => {
		let popup = $(e.currentTarget).closest('.hit-table-grid__td').find('.popup-menu');
		popup.toggleClass('show');
	})

});

function open_modal(modal) {
	modal.addClass("active");
	$('.modal-block').addClass("active");
}

function close_modal() {
	$('.modal,.modal-block').removeClass("active");
}
function init_select_style(){
    $('.select-search').select2(
            {
                language: {
                    "noResults": function(){
                        return "Ничего не найдено";
                    }
                }
            });
//    $('.select-search').on('select2:select', function(e) {
//        console.log('отловили событие 4');
//    });
//    $('.select-search').select2({
//        placeholder: "----",
//        language: {
//           "noResults": function(){
//               return "Ничего не найдено";
//           }
//        },
//        escapeMarkup: function (markup) {
//            return markup;
//        }
//    });
//    $('.select-search').on('select2:select', function(){
//        console.log(this.selectedOptions); // HTMLCollection где каждый элемент - HTMLOptionElement, у которого соответствующие атрибуты
//    });
}
        

function init_radio_style() {
	$('input.js-radio').each(function () {
		if (!$(this).parent().is('.radio_box')) {
			var placeholder = $(this).attr('data-placeholder');

			$(this).wrap('<label class="radio_box"></label>');
			$(this).after('<span class="radio_box__item"></span><span class="radio_box__text">' + placeholder + '</span>');
			var img = $(this).attr('data-image');
			if (img) {
				$(this).siblings('.radio_box__text').before('<span class="radio_box__img" style="background-image: url(' + img + ');"></span>');
			}
		}

		if ($(this).prop('checked')) {
			$(this).parent('.radio_box').addClass('checked');
		} else {
			$(this).parent('.radio_box').removeClass('checked');
		}
	});
};

function init_checkbox_style() {
	$('input.js-checkbox').each(function () {
		if (!$(this).parent().is('.check_box')) {
			var placeholder = $(this).attr('data-placeholder');

			$(this).wrap('<label class="check_box"></label>');
			$(this).after('<span class="check_box__item"></span><span class="check_box__text">' + placeholder + '</span>');

			var img = $(this).attr('data-image');
			if (img) {
				$(this).siblings('.check_box__text').before('<span class="check_box__img" style="background-image: url(' + img + ');"></span>');
			}
		}

		if ($(this).prop('checked')) {
			$(this).parent('.check_box').addClass('checked');
		} else {
			$(this).parent('.check_box').removeClass('checked');
		}
	});
};
var Tab = {
        init:function(item){
            $(item+'-tab li').on('click', function () {
                let index = $(this).index(),
                  content = $(this).closest(item).find(item+'-content');


                $(this).siblings().removeClass("active");
                $(this).addClass("active");

                content.removeClass("active");
                content.eq(index).addClass("active");

              });
            item.each(function () {
                $(this).hide();
           });
        }
    };